import {
  createWorkspaceService,
  getWorkspaceConfiguration,
  updateWorkspaceService
} from '@/api/api.js';
import { log } from '@/constants.js';

export const state = {
  headers: [
    {
      text: 'Workspace',
      align: 'start',
      sortable: true,
      value: 'ws_name'
    },
    {
      text: 'Associated Layer',
      align: 'start',
      sortable: true,
      value: 'name'
    },
    {
      text: 'Visualization Type ',
      align: 'start',
      sortable: true,
      value: 'name'
    }
  ],
  wsConfig: [],
  wsTemplate: {
    service_id: '',
    ws_name: [],
    ws_layers: [],
    globalLayers: [],
    is_graph: false,
    is_animation: false,
    is_table: false,
    is_meteogram: false,
    is_map: false
  },
  selectedWs: [],
  selectedWsConfiguration: []
};

export const mutations = {
  GET_WORKSPACE_CONFIGURATION_MUTATION(state, data) {
    state.wsConfig = data;
  }
};

// ---------------------- GETTERS ---------------------
export const getters = {
  //
};

// ---------------------- Actions ---------------------
export const actions = {
  setWorkspaceForTemplate({ rootState }) {
    if (rootState.wsService.wsConfig.length > 0) {

      rootState.wsService.wsTemplate = {
        service_id: rootState.wsService.wsConfig[0].service_id,
        is_graph: rootState.wsService.wsConfig[0].is_graph,
        is_animation: rootState.wsService.wsConfig[0].is_animation,
        is_table: rootState.wsService.wsConfig[0].is_table,
        is_meteogram: rootState.wsService.wsConfig[0].is_meteogram,
        is_map: rootState.wsService.wsConfig[0].is_map,
        ws_layers: [],
        ws_name: [],
        globalLayers: []
      };

      rootState.wsService.wsConfig.forEach(ws => {
        rootState.wsService.selectedWsConfiguration.findIndex(item => item.name === ws.ws_name) === -1 ?
          rootState.wsService.selectedWsConfiguration.push({ name: ws.ws_name }) : log.error('item exist');
      });

      rootState.wsService.wsConfig.map(e => {
        const randomColor = '#' + (Math.random().toString(16) + '000000').substring(2, 8);
        rootState.wsService.wsTemplate.ws_layers.push({
          name: e.layer_name,
          ws_name: e.ws_name,
          ws_group: e.ws_group,
          ws_id: e.ws_id,
          layer_label: e.layer_label,
          udm: e.udm,
          layer_color: e.layer_color || randomColor
        });
        rootState.wsService.wsTemplate.globalLayers = e.globalLayers;
      }
      );

      rootState.wsService.wsTemplate.ws_layers.forEach(layer => {
        rootState.wsService.wsTemplate.ws_name.findIndex(item => item.ws_group === layer.ws_group && item.ws_id === layer.ws_id) === -1 ?
          rootState.wsService.wsTemplate.ws_name.push({ ws_id: layer.ws_id, ws_group: layer.ws_group, name: layer.ws_name }) : log.error('item exist');
      });
    }
  },
  async createWorkspaceServiceAction({ dispatch }, data) {
    try {
      await createWorkspaceService(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'Workspace configuration created',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'createWorkspaceServiceAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async updateWorkspaceServiceAction({ dispatch }, data) {
    try {
      await updateWorkspaceService(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'Workspace configuration updated',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'updateWorkspaceServiceAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async getWorkspaceConfigurationAction({ commit, dispatch }, data) {
    try {
      const respGetWorkspaceConfiguration = await getWorkspaceConfiguration({
        service_id: data
      });
      commit(
        'GET_WORKSPACE_CONFIGURATION_MUTATION',
        respGetWorkspaceConfiguration.data.getWorkspaceConfiguration
      );
      dispatch('setWorkspaceForTemplate');
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'getWorkspaceConfigurationAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  }
};
